var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "lazy-container",
          rawName: "v-lazy-container",
          value: { selector: "img" },
          expression: "{ selector: 'img' }",
        },
      ],
      staticClass: "CloudinaryImage",
    },
    [
      _c(
        "picture",
        [
          _vm._l(_vm.responsiveImages, function (source, index) {
            return _c("source", {
              key: index,
              attrs: { srcset: source.srcset, media: source.media },
            })
          }),
          _vm._v(" "),
          _c("img", {
            attrs: {
              "data-src": _vm.url,
              "data-loading": _vm.thumbnail,
              width: _vm.width,
              height: _vm.height,
              alt: _vm.alt,
            },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }