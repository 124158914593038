var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isModuleDataExists
      ? _c("div", [
          _vm.displayPopup && !_vm.isAnoterPopupExists
            ? _c("div", { staticClass: "Popup-wrapper" }, [
                _c(
                  "div",
                  { class: _vm.getPopupClass, style: _vm.customStyle },
                  [
                    _c(
                      "div",
                      { staticClass: "Popup-email-modal" },
                      [
                        _c("i", {
                          staticClass: "Icon Icon-close Close-popup-modal",
                          on: { click: _vm.closeModal },
                        }),
                        _vm._v(" "),
                        _c(
                          "EmailSubscription",
                          _vm._b(
                            { attrs: { "display-popup": _vm.displayPopup } },
                            "EmailSubscription",
                            _vm.$props,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.enableStaticPopup &&
          !this.getSessionStorage("emailPopupIdentifier")
            ? _c(
                "div",
                [
                  _c(
                    "EmailSubscription",
                    _vm._b(
                      {
                        class: _vm.backgroundColor,
                        attrs: { "display-popup": _vm.displayPopup },
                      },
                      "EmailSubscription",
                      _vm.$props,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }