<template>
  <div
    v-lazy-container="{ selector: 'img' }"
    class="CloudinaryImage"
  >
    <picture>
      <source
        v-for="(source, index) in responsiveImages"
        :key="index"
        :srcset="source.srcset"
        :media="source.media"
      >
      <img
        :data-src="url"
        :data-loading="thumbnail"
        :width="width"
        :height="height"
        :alt="alt"
      >
    </picture>
  </div>
</template>

<script>
import cloudinary from 'cloudinary-core'

const maxImageSize = 5000
const defaultBreakpoints = [575, 768, 992, 1200, 1441]

export default {
  props: {
    width: {
      type: Number,
      required: false,
      validator (value) {
        return value > 0 && value < maxImageSize
      }
    },
    height: {
      type: Number,
      required: false,
      validator (value) {
        return value > 0 && value < maxImageSize
      }
    },
    id: {
      type: String,
      required: true
    },
    mobileId: {
      type: String,
      required: false
    },
    thumbnail: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      required: true
    },
    cloudinaryName: {
      type: String,
      required: true
    },
    transformation: {
      type: Object,
      required: false,
      default: () => {}
    },
    breakpoints: {
      type: Array,
      required: false,
      default: () => defaultBreakpoints,
      validator: breakpoints => {
        // It checks the array has at least one breakpoint and all the elements are numbers
        return (
          breakpoints.length > 0 &&
          breakpoints.filter(bp => typeof bp !== 'number').length === 0
        )
      }
    }
  },
  data () {
    return {
      cl: new cloudinary.Cloudinary({
        cloud_name: this.cloudinaryName,
        secure: true
      })
    }
  },
  computed: {
    url () {
      return this.cl.url(this.id, { quality: 'auto' })
    },
    responsiveImages () {
      const mobileId = this.mobileId || this.id
      const mobileBp = this.breakpoints[0] - 1
      // It sorts by descending order to avoid matching wrong window sizes
      const breakpoints = this.breakpoints.slice().sort((a, b) => b - a)

      const sources = breakpoints.map(bp => {
        return {
          media: `(min-width: ${bp}px)`,
          srcset: this.cl.url(this.id, {
            transformation: [
              { width: bp, crop: 'scale', quality: 'auto', fetch_format: 'auto', dpr: 'auto' },
              this.transformation
            ]
          })
        }
      })

      // We specifically need to have a configuration for mobile
      // In order to use 'max-width' and mobile specific image
      sources.push({
        media: `(max-width: ${mobileBp}px)`,
        srcset: this.cl.url(mobileId, {
          transformation: [
            { width: mobileBp, crop: 'scale', quality: 'auto', fetch_format: 'auto', dpr: 'auto' },
            this.transformation
          ]
        })
      })

      return sources
    }
  }
}
</script>

<style lang="scss" scoped>
img[lazy="loading"] {
  filter: blur(15px);
}
</style>
