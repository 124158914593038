import Vue from 'vue'
import EmailPopup from 'Patterns/vue/components/EmailPopup.vue'
import CloudinaryImage from 'Patterns/vue/components/CloudinaryImage.vue'
import { Input, Field, Button } from 'buefy'

Vue.use(Input)
Vue.use(Field)
Vue.use(Button)
Vue.component('CloudinaryImage', CloudinaryImage)

const emailSubscriptionSelector = '#vue-email-subscription'
const rootElement = document.querySelector(emailSubscriptionSelector)
const componentProps = rootElement.attributes['data-vue'].value

export default class EmailSubscriptionComponent {
  constructor () {
    this.$trigger = rootElement
    if (this.$trigger) {
      this.app = new Vue({
        el: emailSubscriptionSelector,
        render: createElement => createElement(EmailPopup, {
          props: JSON.parse(componentProps)
        })
      })
    }
  }
}

new EmailSubscriptionComponent()
