<template>
  <div>
    <div v-if="isModuleDataExists">
      <div
        v-if="displayPopup && !isAnoterPopupExists "
        class="Popup-wrapper"
      >
        <div
          :class="getPopupClass"
          :style="customStyle"
        >
          <div class="Popup-email-modal">
            <i
              class="Icon Icon-close Close-popup-modal"
              @click="closeModal"
            />
            <EmailSubscription
              v-bind="$props"
              :display-popup="displayPopup"
            />
          </div>
        </div>
      </div>
      <div v-if="enableStaticPopup && !this.getSessionStorage('emailPopupIdentifier')">
        <EmailSubscription
          :class="backgroundColor"
          v-bind="$props"
          :display-popup="displayPopup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EmailSubscription from './EmailSubscription.vue'
export default {
  name: 'EmailPopup',

  components: {
    EmailSubscription
  },

  props: {
    backgroundColor: {
      type: String,
      required: true
    },
    confirmAgeTickMessage: {
      type: String,
      required: true
    },
    confirmAgeErrorMessage: {
      type: String,
      required: true
    },
    containerType: {
      type: String,
      required: false
    },
    updateConsentErrorMessage: {
      type: String,
      required: true
    },
    desktopImage: {
      type: Object,
      required: false
    },
    mobileImage: {
      type: Object,
      required: false
    },
    validationMessage: String,
    emailTitle: String,
    anotherPopupExists: Boolean,
    moduleData: Object
  },

  data () {
    return {
      bottomOffset: 0,
      popUpDisplayedOnce: false,
      showPopup: false
    }
  },

  computed: {
    displayPopup () {
      !this.isAnoterPopupExists ? this.popupHandler() : this.setShowPopup(false)
      return this.showPopup
    },
    isModuleDataExists () {
      return this.moduleData && this.moduleData.content
    },
    isDisplayPopupEnabled () {
      return this.isModuleDataExists && this.moduleData.content.enablepopup === 'true'
    },
    enableStaticPopup () {
      if (this.isAnoterPopupExists && this.isDisplayPopupEnabled) {
        return true
      } else if (!this.isDisplayPopupEnabled) {
        return true
      }
      return false
    },
    isAnoterPopupExists () {
      return this.anotherPopupExists
    },
    getPopupClass () {
      return 'Popup-email ' + this.backgroundColor
    },
    customStyle () {
      return {
        '--bottomOffset': this.bottomOffset
      }
    }
  },

  created () {
    if (this.heading !== undefined) {
      window.addEventListener('scroll', this.handleScroll)
    }
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    openModal () {
      // Set the offset of popup in relation to the cookie consent banner if present on screen
      this.setBottomOffset()
      this.setShowPopup(true)
    },

    setBottomOffset () {
      setTimeout(() => {
        const consentElem = document.getElementById('consent-banner')
        this.bottomOffset = consentElem ? document.getElementById('consent-banner').offsetHeight : 0
      }, 1000)
    },

    closeModal () {
      this.setShowPopup(false)
      this.setSessionStorage('emailPopupIdentifier', false)
    },

    popupHandler () {
      if (this.isDisplayPopupEnabled && !this.popUpDisplayedOnce) {
        this.setBottomOffset()
        setTimeout(() => {
          this.popUpDisplayedOnce = true
          this.setShowPopup(true)
        }, 4000)
      }
    },

    setShowPopup (val) {
      this.showPopup = val
    },

    setSessionStorage (key, value) {
      const now = new Date()
      const expiry = now.getTime() + 3600000

      const item = {
        value,
        expiry
      }
      sessionStorage.setItem(key, JSON.stringify(item))
    },

    getSessionStorage (key) {
      const itemStr = sessionStorage.getItem(key)

      if (!itemStr) {
        return
      }

      const item = JSON.parse(itemStr)
      const now = new Date()

      if (now.getTime() > item.expiry) {
        sessionStorage.removeItem(key)
        return
      }
      return true
    },

    handleScroll () {
      const secondHeading =
        document.getElementsByTagName('h2')[1] ||
        document.getElementsByTagName('html')[0]
      const fromTop = secondHeading.offsetTop || 2000
      const scrollAmount =
        (window.pageYOffset || document.documentElement.scrollTop) -
        (document.documentElement.clientTop || 0)

      if (scrollAmount > fromTop && !this.getSessionStorage('emailPopupIdentifier')) {
        this.openModal()
        window.removeEventListener('scroll', this.handleScroll)
      }
    }
  }
}
</script>
